<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-3 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <!-- Alert Tidak Punya Akses Halaman -->
    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Approval Cuti"
      </v-alert>
    </v-col>

    <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <v-container fluid v-show="this.valid == true">
      <v-card tile outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            Approval Cuti
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

          <!-- Menu Export Data -->
          <div class="text-center mx-2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                  <span class="subtitle-1">Export Data</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                <!-- PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXCEL -->
                <div>
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Pilih Export File
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Ketika Mau Approve Cuti -->
          <v-dialog
            v-model="DialogApprove"
            max-width="450px"
            scrollable
            persistent
          >
            <v-card
              outlined
              class="rounded-lg"
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-toolbar flat class="rounded-t-lg">
                <v-toolbar-title>Approval Cuti</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="DialogApprove = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>

              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg">
                    <div>
                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n2"><span class="subtitle-1 font-weight-bold">Dibuat Oleh :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.karyawan.Nama"/></div>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Diajukan Tanggal :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.TanggalPengajuan"/></div>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Dari Tanggal :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.TanggalMulai"/></div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Sampai Tanggal :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.TanggalSelesai"/></div>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Jam Mulai :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.JamMulai"/></div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Jam Selesai :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.JamSelesai"/></div>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Alasan Cuti :</span></div>
                          <div><span class="subtitle-1" v-text="''+DataApproval.cuti.AlasanCuti"/></div>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-row no-gutters class="pa-3 mr-1 ml-1">
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <div class="mb-n5"><span class="subtitle-1 font-weight-bold">Keterangan :</span></div>
                          <div><v-text-field color="dark" placeholder="Isi Keterangan" hide-details single-line v-model="DataApproval.Keterangan"></v-text-field></div>
                        </v-col>
                      </v-row>

                      <div v-show="DataNotif.Tujuan == 'Human Resources'">
                        <v-divider></v-divider>
                        <v-row no-gutters class="pa-3 mr-1 ml-1">
                          <v-col cols="12" sm="12" md="12" lg="12" v-show="DataNotif.Tujuan == 'Human Resources'">
                            <div class="mb-n5"><span class="subtitle-1 font-weight-bold">Jumlah Cuti Di ambil :</span></div>
                            <div><v-text-field color="dark" placeholder="0" hide-details single-line type="number" v-model="JumlahCuti"></v-text-field></div>
                          </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row no-gutters class="pa-3 mr-1 ml-1">
                          <v-col cols="12" sm="12" md="12" lg="12" v-show="DataNotif.Tujuan == 'Human Resources'">
                            <div class="mb-n5"><span class="subtitle-1 font-weight-bold">Sisa Hak Cuti :</span></div>
                            <div><v-text-field color="dark" placeholder="0" hide-details single-line readonly type="number" v-model="HakCuti"></v-text-field></div>
                          </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row no-gutters class="pa-3 mr-1 ml-1">
                          <v-col cols="12" sm="12" md="12" lg="12" v-show="DataNotif.Tujuan == 'Human Resources'">
                            <div class="mb-n4"><span class="subtitle-1 font-weight-bold">Opsi :</span></div>
                            <v-row no-gutters>
                              <v-col v-for="pilihan in Select" :key="pilihan.id" cols="12" sm="6"  md="6" lg="6">
                                <v-checkbox color="red darken-4" :value="pilihan.value" :key="pilihan.id" :label="pilihan.text" v-model="PilihanOpsi" hide-details></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-sheet>
                </div>

                <!-- <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg">
                    <div>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Dibuat</v-list-item-title>
                          <v-list-item-subtitle v-text="''+DataApproval.karyawan.Nama"/>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Diajukan Tanggal</v-list-item-title>
                          <v-list-item-subtitle v-text="''+DataApproval.cuti.TanggalPengajuan"/>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Dari Tanggal</v-list-item-title>
                          <v-row dense no-gutters>
                            <div><v-list-item-subtitle v-text="''+DataApproval.cuti.TanggalMulai"/></div>
                            <div class="mr-2 ml-2">S/D</div>
                            <div><v-list-item-subtitle v-text="''+DataApproval.cuti.TanggalSelesai"/></div>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Jam</v-list-item-title>
                          <v-row dense no-gutters>
                            <div><v-list-item-subtitle v-text="''+DataApproval.cuti.JamMulai"/></div>
                            <div class="mr-2 ml-2">S/D</div>
                            <div><v-list-item-subtitle v-text="''+DataApproval.cuti.JamSelesai"/></div>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Jenis Cuti</v-list-item-title>
                          <v-list-item-subtitle v-text="''+DataApproval.cuti.JenisCuti"/>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Alasan Cuti</v-list-item-title>
                          <v-list-item-subtitle v-text="''+DataApproval.cuti.AlasanCuti"/>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Keterangan</v-list-item-title>
                          <v-list-item-subtitle><v-text-field v-model="DataApproval.Keterangan"></v-text-field></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line v-show="DataNotif.Tujuan == 'Human Resources'">
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Jumlah Cuti Di ambil</v-list-item-title>
                          <v-list-item-subtitle><v-text-field type="number" v-model="JumlahCuti"></v-text-field></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line v-show="DataNotif.Tujuan == 'Human Resources'">
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Sisa Hak Cuti</v-list-item-title>
                          <v-list-item-subtitle><v-text-field type="number" v-model="HakCuti"></v-text-field></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item two-line v-show="DataNotif.Tujuan == 'Human Resources'">
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Opsi</v-list-item-title>
                          <v-col v-for="pilihan in Select" :key="pilihan.id" cols="12" sm="6"  md="6" lg="6" class="mt-n6">
                            <v-checkbox color="red darken-4" :value="pilihan.value" :key="pilihan.id" :label="pilihan.text" v-model="PilihanOpsi" hide-details>
                            </v-checkbox>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-sheet>
                </div> -->
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="success" @click="ApproveCutiBtn"><v-icon left>mdi-check-bold</v-icon><span class="mr-1">Approve</span></v-btn>
                <v-btn depressed color="error" class="mr-n2" @click="RejectCutiBtn"><v-icon left> mdi-close-thick</v-icon><span class="mr-1">Reject</span></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog Tambah Approval Cuti -->
          <v-dialog
            v-model="DialogTambahApprovalCuti"
            max-width="600px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Btn Tambah Mode Desktop -->
              <v-btn
                class="text-capitalize mr-n2"
                v-show="mobile == false && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                text
                :disabled="loading == true"
              >
                <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                <span class="mr-n1 subtitle-1">Tambah</span>
              </v-btn>

              <!-- Btn Tambah Mode Mobile -->
              <v-btn 
                class="mr-n2"
                v-show="mobile == true && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                text
                fab
                :disabled="loading == true"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card outlined class="rounded-lg">
              <v-toolbar flat height="65">
                <v-toolbar-title>
                  <span class="text-h5">{{ formTitleApprovalCuti}}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab text small class="mx-n1" @click="KeluarDialogApprovalCuti">
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              
              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <div>
                      <v-row> 
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            clearable
                            label="No. Cuti"
                            color="dark"
                            v-model="editedItem.NoCuti"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            clearable
                            label="Approve By"
                            color="dark"
                            v-model="editedItem.ApproveBy"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            clearable
                            label="Jenis Approval"
                            color="dark"
                            v-model="editedItem.JenisApproval"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-dialog
                            v-model="MenuTglApprove"
                            :return-value.sync="date"
                            width="290px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.TanggalApprove"
                                label="Tanggal Approve"
                                prepend-icon="mdi-calendar"
                                color="dark"
                                readonly
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="editedItem.TanggalApprove"
                                @input="MenuTglApprove = false"
                                year-icon="mdi-calendar-blank"
                                color="red darken-4"
                                class="rounded-lg"
                                locale="id"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            clearable
                            label="Status Approval"
                            color="dark"
                            v-model="editedItem.StatusApproval"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            clearable
                            label="Keterangan"
                            color="dark"
                            v-model="editedItem.Keterangan"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  Status : <span>{{Status}}</span>
                </span>
              <v-spacer></v-spacer>
                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="Simpan"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridapprovalcuti'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="approvalcutiview"
            :dataSource="DataApprovalCuti"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="145" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='NoCuti' headerText='No Cuti' textAlign='Left' width=170></e-column>
              <e-column field='ApproveBy' headerText='Approve By' width=150></e-column>
              <e-column field='JenisApproval' headerText='Jenis Approval' width=150></e-column>
              <e-column field='TanggalApprove' headerText='Tanggal Approve' width=150></e-column>
              <e-column field='StatusApproval' headerText='Status Approval' width=150></e-column>
              <e-column field='Keterangan' headerText='Keterangan' width=150></e-column>
              <e-column field='DiBuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-card>
    </v-container>

    <v-dialog
      v-model="DialogLihatCuti"
      max-width="700px"
      scrollable
    >
      <v-card
        outlined
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
      >
        <v-toolbar flat height="64">
          <v-toolbar-title class="title">
            <span class="text-h5 mx-1">Detail Cuti Approval</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="DialogLihatCuti = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-timeline dense class="ml-n8 mb-n5">
              <v-timeline-item
                v-for="(approval,index) in DataLihatCuti"
                :key="index"
                :color="approval.StatusApproval != 'Approved' ? 'orange' : 'success'"
              >
                <template v-slot:icon>
                  <v-avatar size="31px" color="success" v-show="approval.StatusApproval == 'Approved'">
                    <span class="text-h6 white--text" v-text="approval.Level"></span>
                  </v-avatar>
                  <v-avatar size="31px" color="orange" v-show="approval.StatusApproval != 'Approved'">
                    <span class="text-h6 white--text" v-text="approval.Level"></span>
                  </v-avatar>
                </template>

                <v-card>
                  <div class="pa-3">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="6">
                        <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Di Approve Oleh :</span></div>
                        <div><span class="subtitle-1" v-text="approval.ApproveBy"></span></div>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <div class="mb-n1"><span class="subtitle-1 font-weight-bold">Keterangan :</span></div>
                        <div><span class="subtitle-1" v-text="approval.Keterangan"></span></div>
                      </v-col>
                    </v-row>
                  </div>

                  <v-row no-gutters class="mt-n3">
                    <v-col cols="12">
                      <v-alert text dense color="success" icon="mdi-check-bold" class="ma-2" v-show="approval.StatusApproval == 'Approved'">
                        <span>Approved</span>
                      </v-alert>
                    </v-col>

                    <v-col cols="12">
                      <v-alert text dense color="orange" class="ma-2" v-show="approval.StatusApproval != 'Approved'">
                        <v-progress-circular size="20" width="3" class="mr-3" color="orange" indeterminate></v-progress-circular>
                        <span>Menunggu Approval</span>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card>
              </v-timeline-item>
            </v-timeline>

          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      MenuTglApprove: false,
      HakCuti:null,
      DialogLihatCuti:false,
      DataLihatCuti:[],
      JumlahCuti:null,
      DataNotif:[],
      PilihanOpsi:null,
      Select:[{no:1,text:'Opr Div Head',value:1}],
      mobile:null,
      valid:null,
      DefaultDataApproval:{
          karyawan:{
            Nama:'',
            KodeKaryawan:''
          },
          cuti:{
            id: '',
            TanggalPengajuan: '',
            TanggalMulai: '',
            JamMulai: '',
            TanggalSelesai: '',
            JamSelesai: '',
            JenisCuti: '',
            AlasanCuti: '',
            StatusCuti: ''
          },
      },
      DataApproval:{
          karyawan:{
            Nama:'',
            KodeKaryawan:''
          },
          cuti:{
            id: '',
            TanggalPengajuan: '',
            TanggalMulai: '',
            JamMulai: '',
            TanggalSelesai: '',
            JamSelesai: '',
            JenisCuti: '',
            AlasanCuti: '',
            StatusCuti: ''
          },
      },
      Permission:[],
      DialogApprove:false,
      ValidCreate:false,
      passbaru:null,
      textAktif:true,
      umenu:[],
      verifpassbaru:null,
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:JSON.parse(localStorage.getItem('user')),
      token:localStorage.getItem('token'),
      DialogTambahApprovalCuti: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        id:"",
        NoCuti: "",
        ApproveBy: "",
        JenisApproval: "",
        TanggalApprove: "",
        StatusApproval: "",
        Keterangan: "",
      },
      editedItem: {
        id:"",
        NoCuti: "",
        ApproveBy: "",
        JenisApproval: "",
        TanggalApprove: "",
        StatusApproval: "",
        Keterangan: "",
      },
      toolbarOptions: ['Search'],
      DataApprovalCuti: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    Vue.prototype.$BukaNotifPengajuanCuti = this.BukaNotifPengajuanCuti
    if (this.Permission.some(a => a == 'R')) {
      this.commands.push({buttonOption: { cssClass: "e-flat Lihat", iconCss: "e-icons mata", content:' '} })
      this.valid = true
      this.getdata()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
  },
  
  created(){
    //get action for this page
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleApprovalCuti() {
      return this.editedIndex === -1 ? 'Tambah Data Approval Cuti' : 'Ubah Data Approval Cuti'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    PilihanOpsi(){
      console.log('ceked',this.PilihanOpsi)
    },
      // let a = this.DataAppovalCuti.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahApprovalCuti (val) {
      val || this.KeluarDialogApprovalCuti()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    ApproveCutiBtn(){
      this.Toast('Approval Di Setujui')
      let notiftujuan = this.$user.jabatan.Atasan.id
      if (this.PilihanOpsi != null) {
        notiftujuan = this.PilihanOpsi
      }
      api.put("/approvecuti/"+this.DataApproval.id+'/?token='+this.token,{
            NoCuti: this.DataApproval.NoCuti,
            ApproveBy: this.$user.Nama,
            JenisApproval: this.DataApproval.JenisApproval,
            TanggalApprove: new Date().toJSON().slice(0,10),
            StatusApproval: "Approved",
            Keterangan: this.DataApproval.Keterangan,
            Dari:this.$user.KodeKaryawan,
            HakCuti:this.HakCuti,
            JumlahCuti:this.JumlahCuti,
            TujuanNotif:notiftujuan,
            Konten:this.DataApproval.Keterangan,
            Opsi:this.PilihanOpsi,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              if(this.$route.params.notifikasi != undefined){
                  delete this.$route.params.notifikasi
                }
              this.getdata()
              this.AlertBerhasilTerubah = true
						}
						
					})
      this.DialogApprove = false
    },
    RejectCutiBtn(){
      this.Toast('Approval Di Reject')
      let TujuanNotif = [this.DataApproval.karyawan.KodeKaryawan]
      if (this.DataApproval.Level == 2) {
        TujuanNotif = [this.$route.params.notifikasi.karyawan.KodeKaryawan,this.DataApproval.karyawan.KodeKaryawan]
      }
      if (this.DataApproval.Level == 3) {
        TujuanNotif = [this.$route.params.notifikasi.karyawan.KodeKaryawan,this.DataApproval.karyawan.KodeKaryawan]
      }
      api.put("/rejectcuti/"+this.DataApproval.id+'/?token='+this.token,{
            NoCuti: this.DataApproval.NoCuti,
            ApproveBy: this.$user.Nama,
            JenisApproval: this.DataApproval.JenisApproval,
            TanggalApprove: new Date().toJSON().slice(0,10),
            Keterangan: this.DataApproval.Keterangan,
            Dari:this.$user.KodeKaryawan,
            StatusApproval: "Rejected",
            // Tujuan:this.DataApproval.karyawan.KodeKaryawan,
            TujuanNotif:TujuanNotif,
            Konten:this.DataApproval.Keterangan
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              if(this.$route.params.notifikasi != undefined){
                  delete this.$route.params.notifikasi
                }
              this.getdata()
              this.AlertBerhasilTerubah = true
						}
						
					})
      this.DialogApprove = false
    },

    BukaNotifPengajuanCuti(data){
      this.DialogApprove = true
      this.DataNotif = data
      let approv = this.DataApprovalCuti.find(x => x.NoCuti == data.KodeNotifikasi)
      this.DataApproval = approv.length > 0 ? approv[0] : []
      this.HakCuti = this.DataApproval.karyawan.Cuti
    },

    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataApprovalCuti.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitleApprovalCuti === "Tambah Data Approval Cuti"){
        this.Toast('Data Disimpan')
          api.post('/approvalcuti?token='+this.token,{
            NoCuti: this.editedItem.NoCuti,
            ApproveBy: this.editedItem.ApproveBy,
            JenisApproval: this.editedItem.JenisApproval,
            TanggalApprove: this.editedItem.TanggalApprove,
            StatusApproval: this.editedItem.StatusApproval,
            Keterangan: this.editedItem.Keterangan,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleApprovalCuti === "Ubah Data Approval Cuti"){
        this.Toast('Data Disimpan')
         api.put("/approvalcuti/"+this.editedItem.id+'/?token='+this.token,{
            NoCuti: this.editedItem.NoCuti,
            ApproveBy: this.editedItem.ApproveBy,
            JenisApproval: this.editedItem.JenisApproval,
            TanggalApprove: this.editedItem.TanggalApprove,
            StatusApproval: this.editedItem.StatusApproval,
            Keterangan: this.editedItem.Keterangan,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogApprovalCuti()    
    },

    commandClick: function(args) {
      // if (args.target.classList.contains("custombutton")){
      // }else 
      if (args.target.classList.contains("Lihat")) {
        this.DialogLihatCuti = true
        let a = this.DataApprovalCuti.filter( function(item){return (item.NoCuti == args.rowData.NoCuti);} );
        this.DataLihatCuti = a[0]
        console.log(a)
      }else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/approvalcuti/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              // this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.NoCuti = args.rowData.NoCuti
        let data = args
            this.editedIndex = 1;
            this.editedItem = data.rowData
            this.DialogTambahApprovalCuti = true
      }
    },

    PdfExport(){
      let data = document.getElementById('approvalcutiview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportApprovalCuti.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridappovalcuti.columns[0].visible = false;
      // this.$refs.gridappovalcuti.columns[1].visible = true;
      this.$refs.gridapprovalcuti.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridapprovalcuti.excelExport();
      // console.log(this.$refs.gridappovalcuti)
      // let data = document.getElementById('appovalcutiview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogApprovalCuti () {
      this.DialogTambahApprovalCuti = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      api.get('/approvalcuti?token='+this.token,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
          if (res) {
            let datas = [], approval = [], approvalcuti = []
            let es = res.data
            var flags = [], l = res.data.length, i;
            for( i=0; i<l; i++) {
                if( flags[res.data[i].NoCuti]) continue;
                flags[res.data[i].NoCuti] = true;
                datas = es.filter(w=> w.NoCuti == res.data[i].NoCuti)
                datas.NoCuti = datas[datas.length-1].NoCuti
                datas.ApproveBy = datas[datas.length-1].ApproveBy
                datas.TanggalApprove = datas[datas.length-1].TanggalApprove
                datas.JenisApproval = datas[datas.length-1].JenisApproval
                datas.StatusApproval = datas[datas.length-1].StatusApproval
                datas.Keterangan = datas[datas.length-1].Keterangan
                datas.DiBuatOleh = datas[datas.length-1].DiBuatOleh
                datas.DiBuatTgl = datas[datas.length-1].DiBuatTgl
                datas.DiUbahOleh = datas[datas.length-1].DiUbahOleh
                datas.DiUbahTgl = datas[datas.length-1].DiUbahTgl
                let s = datas
                let sa = datas.reverse()
                approval.push(s)
                approvalcuti.push(sa)
            }
            console.log('approval',approval)
            console.log('dirp',approvalcuti)
            // this.DataApprovalCuti = res.data
            // console.log('datacuti',res.data)
            // cek notif cuti
            let data = res.data
            data = data.reverse()
            if(this.$route.params.notifikasi != undefined){
            let notifcuti = this.$route.params.notifikasi
              if (notifcuti.Judul == 'Pengajuan Cuti') {
                this.DataNotif = notifcuti
                this.DialogApprove = true
                console.log(notifcuti)
                let approv = data.find(x => x.NoCuti == notifcuti.KodeNotifikasi)
                this.DataApproval = approv
                this.HakCuti = this.DataApproval.karyawan.Cuti
                 console.log('hasil',approv)
              }           
            }
          this.DataApprovalCuti = approvalcuti
          this.loading = false
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>


{% endraw %}